<template>
  <div class="login d-flex justify-center">
    <div class="card-wrap w-full">
      <pageTitle icon="icon-login" text="login"></pageTitle>

      <v-form ref="form" lazy-validation>
        <inputBlock class="mb-2" title="account" :inputText.sync="account" column :rules="[required, ...phoneRules]"></inputBlock>
        <inputBlock class="mb-2" title="password" type="password" :inputText.sync="password" column :rules="[required]"></inputBlock>
        <div class="d-flex justify-end text-decoration-underline red--text can-click rem-md-13 rem-8" @click="$router.push({name: 'Registry', params: {lang: $store.state.locale}})">{{ $t('toRegistry') }}</div>
      </v-form>

      <div class="d-flex justify-center">
        <btn class="mr-3" buttonText="login" @clickBtn="login()"></btn>
        <btn buttonText="forgetPw" @clickBtn="resetShow=true; $refs.resetForm.reset()"></btn>
      </div>
    </div>

    <dialogs :title="dialogTitle" :content="'enterAgain'"></dialogs>
    <dialogs :resetShow.sync="resetShow" isCustom>
      <template slot="custom" class="w-full">
        <v-form ref="resetForm" lazy-validation>
          <inputBlock class="mb-2" title="phone" :inputText.sync="phone" column :rules="[required, ...phoneRules]"></inputBlock>
          <inputBlock class="mb-2" title="validateCode" :inputText.sync="validateCode" column isSend @send="sendValidateCode(phone, 4)"></inputBlock>
          <inputBlock class="mb-2" title="newPassword" :inputText.sync="newPw" type="password" column :rules="[required]"></inputBlock>
        </v-form>
        <btn isCenter :buttonText="'confirm'" @clickBtn="forgetPw()"></btn>
      </template>
    </dialogs>
  </div>
</template>
<script>
import base from '@/mixin/base'
import inputBlock from '@/components/inputBlock.vue'
import pageTitle from '@/components/pageTitle.vue'
import btn from '@/components/btn.vue'
import dialogs from '@/components/dialogs.vue'
export default {
  name: "Login",
  mixins: [base],
  data(){
    return {
      account: '',
      password: '',
      dialogTitle: '',
      // reset
      resetShow: false,
      phone: '',
      validateCode: '',
      newPw: ''
    }
  },
  components:{
    inputBlock,
    pageTitle,
    btn,
    dialogs
  },
  methods:{
    async login(){
      if (this.$refs.form.validate()){
        let data = {
          phone_number: this.account.slice(1),
          phone_country_code: this.account[0],
          password: this.password
        }
        let result = await this.$store.dispatch('login', data)
        console.log('result', result)
        if (result.status === 672){
          this.$toasted.show(this.$t('loginSuccess'))
          await this.$store.commit('updateToken', result.data)
          this.$cookies.set('token', result.data)
          await this.$store.dispatch('getUserInfo')
          this.$router.push({name: 'Home', params: {lang: this.$store.state.locale}})
        }else if (result.status === 674){
          this.dialogTitle = 'passwordNotCorrect'
          this.$store.commit('updateDialogShow', true)
        }else if (result.status === 675){
          this.dialogTitle = 'accountNotExist'
          this.$store.commit('updateDialogShow', true)
        }else{
          this.dialogTitle = 'loginFail'
          this.$store.commit('updateDialogShow', true)
        }
      }
    },
    async forgetPw(){
      if (this.$refs.resetForm.validate()){
        let data = {
          password: this.newPw,
          phone_number: this.phone.slice(1),
          phone_country_code: this.phone[0],
          phone_verify_code: this.validateCode
        }
        let result = await this.$store.dispatch('resetPw', data)
        console.log('result', result)
        if (result.status === 678){
          this.$toasted.show(this.$t('resetPwSuccess'))
          this.$refs.resetForm.reset()
          this.resetShow = false
        }else if (result.status === 732){
          this.dialogTitle = 'codeHasExpired'
          this.$store.commit('updateDialogShow', true)
        }else if (result.status === 733){
          this.dialogTitle = 'codeWrong'
          this.$store.commit('updateDialogShow', true)
        }else if (result.status === 675){
          this.dialogTitle = 'accountNotExist'
          this.$store.commit('updateDialogShow', true)
        }else{
          this.dialogTitle = 'resetPwFail'
          this.$store.commit('updateDialogShow', true)
        }
      }
    }
  },
}
</script>