<template>
  <div class="d-flex flex-column justify-center align-center red--text mb-6">
    <img :src="`${require(`@/assets/img/${icon}.png`)}`" width="60px">
    <div class="rem-18">{{ $t(text) }}</div>
  </div>
</template>
<script>
export default {
  props:{
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
  }
}
</script>